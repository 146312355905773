import React from 'react';
import "./Practic.css";
import Calculator from "../StornoCalculator";
import {motion} from "framer-motion";

export default function Practic() {
    return (
        <div className='practic-page-container'>
            <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                        transition={{default: {duration: 0.5}}}>
                <div className='practic-box-container'>
                    <div className='practic-columns'>
                        <h1 className='intro-header'>
                            Praktické
                        </h1>
                        <h2>
                            Termín
                        </h2>
                        <p>
                            Slzy Faerunu momentálně nemají vypsané žádné další běhy. Děkujeme všem za skvělé proběhlé čtyři běhy! Třeba se jednou zase setkáme v tomto světě!
                            <br/>
                        </p>
                        <h2>
                            Místo
                        </h2>
                        <p>
                            Hra se odehrává v prostoru skanzenu Země Keltů v Nasavrkách a jeho blízkém okolí.
                        </p>
                        <h2>
                            Crossgender
                        </h2>
                        <p>
                            Na běhu, kde je povolen crossgender bude na hráči, zda bude chtít hrát postavu mužského,
                            či ženského pohlaví.
                        </p>
                                                <h2>
                            Kostýmy
                        </h2>
                        <p> Kostýmy si primárně hráč zajišťuje sám, stejně jako doplňky, včetně larpových zbraní a
                            protetiky. Vše lze zároveň za poplatek půjčit (viz. Cena). V rámci zapůjčeného kostýmu však
                            nedostanete boty, vhodné zavazadlo (žebradlo, batoh). Pokud byste měli problémy se sehnáním
                            těchto věcí, dejte vědět, pokusíme se najít nějaké přijatelné řešení. Naopak válečnickým
                            postavám se pokusíme zajistit zbroj v rámci kostýmu.
                        </p>
                        <h2>
                            Jídlo a pití
                        </h2>
                        <p>Během hry budeme poskytovat teplé a studené jídlo (ve vegetariánské a masové verzi). Jídlo
                            budeme podávat od páteční večeře až po nedělní snídani. Zároveň během hry se budeme snažit
                            zásobit hráče drobnými svačinkami. Stejně tak bude po celou dostupný čaj a voda.
                            <br/>
                            Kromě jídla a pití zahrnutého v rámci hráčského poplatku bude po většinu hry otevřená
                            taverna ve které si budete moci koupit pití. Poprosíme vás, abyste si dovezli vlastní
                            stylové nádobí, které se vám ale v případě absence pokusíme poskytnout sami.
                        </p>
                        <h2>
                            Ubytování
                        </h2>
                        <p>Spaní bude zajištěno ve fantasy stanech, ve kterých budete ubytovaní podle vašich družin.
                            Je možné, že některé družiny pak budou ještě rozdělené do více stanů. Jiné družiny budou
                            sdílet stany větší. Doporučujeme si přivést dobrou karimatku a spacák. Zároveň Vás
                            poprosíme, abyste v herní době své ubytování uklidili a zakryly všechny moderní
                            prvky.
                            <br/>
                            V rámci lokace bude přístupná pitná voda, která bude vhodná na mytí nádobí a základní
                            hygienu (ovšem nejsme schopni zajistit sprchy).
                        </p>
                        <h2>
                            Cena
                        </h2>
                        <p>
                            Cena hry se dělí do cenových balíčků, které nemají jakýkoliv vliv na výběr postavy, kostým,
                            ani rekvizity.
                            <br/>
                            Balíčky jsou pouze způsobem, jak hru sponzorovat a získat díky tomu nějakou
                            drobnost z naší strany.
                        </p>
                        <li>
                            Dobrodruh
                        </li>
                        <p>
                            3 000 Kč
                            <br/>
                            Základní poplatek za hru, který zahrnuje veškeré poplatky se hrou spojené, včetně
                            ubytování a zajištění jídla během akce.
                        </p>
                        <li>
                            Žoldnéř z Letohradu
                        </li>
                        <p>
                            3 200 – 3 499 Kč
                            <br/>
                            ponzorský balíček zahrnuje pohlednici ze Stříbrodolu a jednu ze slz Faerûnu.
                        </p>
                        <li>
                            Hrdina z Baldurovy brány
                        </li>
                        <p>
                            3 500 – 4 499 Kč
                            <br/>
                            Sponzorský balíček zahrnuje pohlednici ze Stříbrodolu a jednu ze slz Faerûnu.
                            Stejně jako svícen zasvěcen entitě Faerûnu (vybranou dle vaší postavy).
                        </p>
                        <li>
                            Lord z Panské aliance
                        </li>
                        <p>
                            4 500 – 7 999 Kč
                            <br/>
                            Sponzorský balíček zahrnuje pohlednici ze Stříbrodolu a jednu ze slz
                            Faerûnu. Stejně jako svícen zasvěcen entitě Faerûnu (vybranou dle vaší postavy), k tomu
                            získáte originální korbel s logem hry! Stejně jako váš portrét se stane rekvizitou hry pro
                            další uvedení.
                        </p>
                        <li>
                            Božstvo Faerûnu
                        </li>
                        <p>
                            8 000 Kč a více
                            <br/>
                            Sponzorský balíček zahrnuje pohlednici ze Stříbrodolu a jednu ze slz
                            Faerûnu. Stejně jako svícen zasvěcen entitě Faerûnu (vybranou dle vaší postavy), k tomu
                            získáte korbel s logem hry. Dále pak se váš portrét stane rekvizitou hry pro další uvedení.
                            Jako poděkování za štědrou podporu pak i D&D one-shot pro 6 hráčů, dle vaší volby.
                        </p>
                        <p>
                            Pokud nevlastníte kostým, larpovou zbraň, či případnou protetiku vhodnou pro vaší postavu,
                            je možnost zapůjčit tyto části od organizačního týmu za drobný poplatek. Přihlásit se k
                            zapůjčení bude možné až po výběru postavy prostřednictvím formuláře, který vám bude dodán.
                            <br/>
                            Konkrétní poplatky:
                            <br/>
                            <li className="p-li">
                                500 Kč za zapůjčení kompletního kostýmu
                            </li>
                            <li className="p-li">
                                200 Kč za zapůjčení larpové zbraně vhodné pro vaši postavu
                            </li>
                            <li className="p-li">
                                200 Kč za zapůjčení protetiky, pokud jí vaše postava vyžaduje (např. elfí uši,
                                tieflingské
                                rohy, trpasličí vousy atd.)
                            </li>
                        </p>
                        <h2>
                            Storno poplatek
                        </h2>
                        <p>
                            Poplatek za hru je potřeba uhradit do termínu, který bude pro daný rok stanoven.
                            Neuhrazená místa se po termínu zaplacení uvolní náhradníkům.
                        </p>
                        <p>
                            V případě zrušení účasti na hře po termínu zaplacení, je účtován storno poplatek ve výši 10%
                            celkové ceny.
                        </p>
                        <p>
                            V případě zrušení účasti na hře 30 dnů před hrou, je účtován storno poplatek ve výši 50%
                            celkové ceny.
                        </p>
                        <p>
                            V případě zrušení účasti na hře 14 dnů před hrou, je účtován storno poplatek ve výši 100%
                            celkové ceny.
                        </p>
                        <p>
                            Pokud je na odhlášené místo nalezen platící účastník, lze vrátit i částku převyšující storno
                            poplatek, maximálně však do výše 90% celkové částky.
                        </p>
                        <p>
                            Při stornu 50% se jedná o částku uhrazenou novým účastníkem,
                            která převyšuje 50% uhrazeného poplatku původním účastníkem
                            (do výše 90% uhrazeného účastnického poplatku).
                        </p>
                        <p>
                            Při stornu 100% se jedná o částku
                            uhrazenou novým účastníkem (do výše 90% uhrazeného účastnického poplatku).
                        </p>
                        <p>
                            Půjčovné nepatří mezi účastnický poplatek a vrací se v plné výši,
                            kromě případů, kdy se hráč odhlásí 14 dní před hrou.
                        </p>
                        <p>
                            Příklad: Pokud se odhlásím 20 dní před hrou, tak dostanu 50% z uhrazeného poplatku a 100% z půjčovného.
                        </p>
                        <h2>
                            Kalkulačka storno poplatku
                        </h2>
                        <Calculator/>
                        <p>
                            Kalkulačka je ponechána nastavená pro proběhlé běhy v roce 2024. Je možné si na ní otestovat storno podmínky prakticky.
                            <br/>
                            <br/>
                        Termín zaplacení pro rok 2024 byl stanoven na 28.4.
                        </p>
                    </div>
                </div>
            </motion.div>
        </div>
    );

}
