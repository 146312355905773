import WSPGallery from './Gallery';
import './Gallery.css';

function ILYIT() {

    const galleryImages = [
        {img: "./gallery-img/1.jpeg",
            author: "Petr Zip Hájek"
        },
        {img: "./gallery-img/2.jpeg",
            author: "Ondra Tůma"
        },
        {img: "./gallery-img/3.jpeg",
            author: "Ondra Tůma"
        },
        {img: "./gallery-img/4.jpg",
            author: "Petr Zip Hájek"
        },
        {img: "./gallery-img/5.jpg",
            author: "Ondra Tůma"
        },
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-166.webp?bwg=1704290038",
            author: "Petr Zip Hájek"},
        {img: "/gallery-img/8.jpg",
            author: "Blanka Clar Lednická"},
        {img: "/gallery-img/9.jpg", author: "Blanka Clar Lednická"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-182.webp?bwg=1704290038",
            author: "Petr Zip Hájek"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-033.webp?bwg=1704290037",
            author: "Petr Zip Hájek"},
        {img: "/gallery-img/10.jpg", author: "Ondra Tůma"},
        {img: "/gallery-img/7.jpg", author: "Blanka Clar Lednická"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-053.webp?bwg=1704290037",
            author: "Petr Zip Hájek"},
        {img: "/gallery-img/11.jpg", author: "Ondra Tůma"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-051.webp?bwg=1704290037",
            author: "Petr Zip Hájek"},
        {img: "/gallery-img/6.jpg", author: "Ondra Tůma"},
        {img: "/gallery-img/l52.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l120.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l130.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l134.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l147.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l151.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l161.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/l171.jpg", author: "Lukáš Blokša"},
        {img: "/gallery-img/z013.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z089.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z096.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z114.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z122.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z127.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z152.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z167.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z204.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z220.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z245.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z251.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z337.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z350.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z376.jpg", author: "Petr Zip Hájek"},
        {img: "/gallery-img/z381.jpg", author: "Petr Zip Hájek"},

    ]

    return (
        <div className="App">
            <br/>
            <br/><br/>

            <WSPGallery
                galleryImages={galleryImages}
            />

            <br/><br/>
        </div>
    );
}

export default ILYIT;